import httpClient, {ClientId} from "./HttpClient";
import { LanguageResponse, ClientLanguageResponse } from "@/types/Language/Response/LanguageResponse";

const getLanguage = (): Promise<LanguageResponse[]> => {
  const endPoint = `/api/Language/${ClientId}`;
  return httpClient.get(endPoint).then((response) => response.data);
};
const getClientLanguage = (): Promise<ClientLanguageResponse[]> => {
  const endPoint = `/api/Language/Client/${ClientId}`;  
  return httpClient.get(endPoint).then((response) => response.data);
};

export { getLanguage, getClientLanguage };