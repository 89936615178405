<template>
	<b-container fluid class="runningTextBg" v-b-modal="'runningtext-modal'">
		<b-container class="runningTextContent">
			<Vue3Marquee class="marquee" :duration="30" :pause-on-hover="true" :pause-on-click="true">
				<span v-for="(runningText, index) in getRunningText" :key="`runningText-${index}`" class="runningTextItem">{{
					runningText
				}}</span>
			</Vue3Marquee>
		</b-container>
	</b-container>

	<RunningTextModal :runningTextList="runningTextList"></RunningTextModal>
</template>

<script>
import RunningTextModal from "@/components/modal/RunningTextModal.vue";
export default {
	components: {
		RunningTextModal,
	},
	data() {
		return {
			runningTextList: [],
		};
	},
	watch: {
		"$i18n.locale": function (newLanguage) {
			this.runningTextList = this.$store.getters["announcement/getRunningText"](newLanguage);
		},
	},
	computed: {
		getRunningText() {
			return this.runningTextList;
		},
	},
	async created() {
		await this.$store.dispatch("announcement/fetchRunningText").then(() => {
			this.runningTextList = this.$store.getters["announcement/getRunningText"]();
		});
	},
};
</script>

<style scoped>
.runningTextBg {
	height: 60px;
	background: #ffe8eb;
	align-items: center;
	display: flex;
	cursor: pointer;
}
.runningTextContent {
	/*max-width: 85%;*/
	color: #3e0d0c;
	align-items: center;
	display: block;
}
.runningTextItem {
	margin: 0px 3rem;
}

@media (max-width: 767px) {
	.runningTextBg {
		height: 100%;
		font-size: 0.8rem;
		padding: 0.275rem 0px;
	}
}
</style>
