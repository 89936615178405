<template>
	<b-row align-h="center" class="navContainer d-none d-md-flex">
		<div class="navContent">
			<nav>
				<NavBarLink> </NavBarLink>
			</nav>
		</div>
	</b-row>
</template>

<style scoped>
nav {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 20px 30px 14px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}

.navContainer {
	top: 80px;
	position: absolute;
	z-index: 999;
	transform: translateX(-50%);
	left: 50%;
}

.navContent {
	background-color: rgba(62, 13, 12, 0.85);
	border: 2px solid #9e2537;
	border-radius: 20px;
	width: 40vw;
	max-width: 494px;
	max-height: 105px;
}

@media (max-width: 991px) {
	nav {
		padding: 20px 0px 14px;
	}
	.navContent {
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media (max-width: 767px) {
	.navContent {
		width: 100%;
	}

	nav {
		padding: 10px 0px 2px;
	}
}
</style>

<script>
import NavBarLink from "@/components/content/Header/HeaderNavLink";
export default {
	components: {
		NavBarLink,
	},
	computed: {},
	data() {
		return {};
	},
};
</script>
