<template>
	<router-link v-for="navbar in navbarList" :to="navbar.link" :key="navbar.title">
		<div class="navIconContent">
			<font-awesome-icon :icon="navbar.icon" class="navIcon" />
		</div>
		<div class="navLink">{{ $t("HeaderNavLink." + navbar.title) }}</div>
	</router-link>
</template>

<style scoped>
.navIconContent {
	background: #3e0d0c;
	box-shadow: -2px -5px 10px #801b19;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	margin: 0 auto;
}

.navIconContent:hover,
.router-link-active .navIconContent,
.router-link-exact-active .navIconContent {
	background: #ed6461;
	box-shadow: -2px -5px 10px #f38f8c;
}

.navIcon {
	color: #ed6461;
}

.navIconContent:hover .navIcon,
.router-link-active .navIcon,
.router-link-exact-active .navIcon {
	color: #ffffff;
}

.navLink {
	color: #ffffff;
	padding-top: 10px;
	font-family: SF Pro Display;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
}

a,
a:hover {
	text-decoration: none;
}

@media (max-width: 991px) {
	.navLink {
		font-size: 11px;
	}
}

@media (max-width: 767px) {
	.navLink {
		padding-top: 2px;
	}

	.navIconContent {
		box-shadow: -2px -2px 10px #801b19;
	}

	.navIconContent:hover,
	.router-link-active .navIconContent,
	.router-link-exact-active .navIconContent {
		box-shadow: -2px -2px 10px #f38f8c;
	}
}
</style>

<script>
import { navBar } from "@/constant";

export default {
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		navbarList() {
			var navBarFiltered = navBar.filter((i) => i.view.includes("pc"));
			if (!this.isLogin) {
				navBarFiltered = navBarFiltered.filter((i) => i.afterLogin == false);
			}
			return navBarFiltered;
		},
	},
};
</script>
