
// import { HubConnectionBuilder } from "@aspnet/signalr";
import { defineComponent } from "vue";
import { RootMutations } from "@/store/mutations";
import { HubConnectionBuilder } from "@microsoft/signalr";

export default defineComponent({
	data() {
		return {
			connection: new HubConnectionBuilder().withUrl(`${process.env.VUE_APP_Signalr_Connection}`).build(),
			toastPosition: this.$isMobile() ? "bottom-center" : "top-center",
		};
	},
	async mounted() {
		await this.start();
		this.onReceiveMemberRefreshBalance();
		this.onApproveTicket();
		this.onRejectTicket();
		this.onUpdateVendorMaintenance();
		this.connection.onclose(this.start);
	},
	methods: {
		async start() {
			try {
				await this.connection.start();
				this.addToGroup();
				this.addToGroupForMT();
			} catch (err) {
				setTimeout(() => {
					this.start();
				}, 5000);
			}
		},
		async addToGroup() {
			const loginId = this.$store.getters["identityServer/getLoginId"];
			const clientId = process.env.VUE_APP_IdentityServer_ClientId;
			const connectionIDString = `${clientId}_${loginId}`;
			await this.connection
				.invoke("AddToGroup", `${process.env.VUE_APP_Signalr_Connection}`, connectionIDString)
				.then(function () {
					console.log("added to group " + connectionIDString);
				})
				.catch(function (err) {
					console.log(err);
				});
		},
		async addToGroupForMT() {			
			const connectionIDString = `Maintenance`;
			await this.connection
				.invoke("AddToGroup", `${process.env.VUE_APP_Signalr_Connection}`, connectionIDString)
				.then(function () {
					console.log("added to group " + connectionIDString);
				})
				.catch(function (err) {
					console.log(err);
				});
		},
		async onReceiveMemberRefreshBalance() {
			this.connection.on("ReceiveMemberRefreshBalanceTrigger", () => {
				//add in code
			});
		},
		async onApproveTicket() {
			this.connection.on("ApproveTicketTrigger", (ticketId: string) => {
				//add in code
				this.toast.success(this.$t("TheSignalr.TicketSuccess", { id: ticketId }), { 
					position: this.toastPosition,
					timeout: 8000,
					onClose: () => {
						this.$store.dispatch("memberAccount/fetchVendorBalance", { isFromDB: false });
						this.$router.push({ path: '/statement' })
						},
				});
			});
		},
		async onRejectTicket() {
			this.connection.on("RejectTicketTrigger", (ticketId: string) => {
				//add in code
				this.toast.error(this.$t("TheSignalr.TicketReject", { id: ticketId }), {
					position: this.toastPosition,
					timeout: 8000,
				});
			});
		},
		async SendAccountCreationNotification() {
			this.connection.on("SendAccountCreationNotification", () => {
				//add in code
				this.$store.commit(RootMutations.SET_VENDOR_REGISTER_STATUS, true);
			});
		},
		async onUpdateVendorMaintenance() {			
			this.connection.on("ReceiveMaintenanceUpdatedNotification", () => {
				//add in code
				console.log("Maintenance Updated");
			    this.$store.dispatch("vendors/fetchVendor");
			});			
		},
	},
});
