<template>
	<b-row>
		<b-col cols="6" lg="6" sm="6" class="my-3 px-4">
			<div>{{ $t("DownloadQR.Android") }}</div>
			<qrcode-vue :value="androidDownloadUrl" level="H" class="my-2 w-75 h-auto mx-auto d-none d-md-block" />
			<base-image
				:imgSrc="androidQrUrl"
				mode="QrImage mx-auto d-block d-md-none"
				:isFullUrl="true"
				fluid-grow
			></base-image>
			<b-button variant="downloadGame" @click="downloadGame(androidDownloadUrl)">{{
				$t("DownloadQR.Download")
			}}</b-button>
		</b-col>
		<b-col cols="6" lg="6" sm="6" class="my-3 px-4">
			<div>{{ $t("DownloadQR.IOS") }}</div>
			<qrcode-vue :value="iosDownloadUrl" level="H" class="my-2 w-75 h-auto mx-auto d-none d-md-block" />
			<base-image :imgSrc="iosQrUrl" mode="QrImage mx-auto d-block d-md-none" :isFullUrl="true" fluid-grow></base-image>
			<b-button variant="downloadGame" @click="downloadGame(iosDownloadUrl)">{{ $t("DownloadQR.Download") }}</b-button>
		</b-col>
		<b-col cols="12" lg="12" sm="12" class="my-3 px-4">
		<template v-if="!isMaintenance">
			<b-button variant="downloadGame" @click="downloadGame(html5Url)">{{ $t("DownloadQR.PlayNow") }}</b-button>
			</template>
			<template v-else>
			<b-button variant="downloadGame" @click="maintenancepopup">{{ $t("DownloadQR.PlayNow") }}</b-button>
			</template>
		</b-col>
	</b-row>
</template>

<style scoped>
.btn-downloadGame {
	height: 39px;
	width: 100%;
	background: linear-gradient(90deg, #f03f5a -4.17%, #e995a2 106.85%);
	border-radius: 19.5px !important;
}
.btn-downloadGame:hover,
.btn-downloadGame:active {
	background: linear-gradient(90deg, #f03f5a -4.17%, #e995a2 106.85%) !important;
}

@media (max-width: 767px) {
	.btn-downloadGame {
		width: 80% !important;
	}
}
.QrImage {
	width: 50% !important;
	height: auto;
	margin: 1rem 0;
}
</style>
<script>
import QrcodeVue from "qrcode.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
export default {
	data() {
		return {
			androidQrUrl: require("@/assets/android.png"),
			iosQrUrl: require("@/assets/ios.png"),
			iosDownloadUrl: "",
			androidDownloadUrl: "",
			html5Url: "https://001.new9k.com/h5",
		};
	},
	methods: {
		downloadGame(downloadUrl) {
			window.open(downloadUrl, "_blank");
		},
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("Game is under maintenance. Please try again later."), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		}
	},
	computed: {
	    isMaintenance(){
			return this.$store.getters["vendors/getVendor"].maintenance;		
		}
	},
	components: {
		QrcodeVue,
		BaseImage,
	},
	async created() {
		await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "ANDROID" });
		await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "IOS" });
		this.androidDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("ANDROID").downloadUrl;
		this.iosDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("IOS").downloadUrl;
	},
};
</script>
