<template>
	<b-container class="mt-5">
		<WithdrawalChart />
	</b-container>
</template>

<script>
import { defineComponent } from "vue";
import WithdrawalChart from "./WithdrawalChart.vue";

export default defineComponent({
	name: "HomeWithdrawChart",
	components: {
		WithdrawalChart,
	},
});
</script>
