<template>
  <b-container class="my-5">
    <b-row align-h="between">
      <b-col cols="6" class="homeTitle d-flex justify-content-start">{{ $t("HomePromotion.PROMOTIONS") }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="6" sm="6" v-for="promoItem in promoList" :key="promoItem" @click="openPromoPage">
        <div class="promoItem">
          <base-image mode="promoImg" :imgSrc="imgUrl(promoItem)" :isFullUrl="false" fluid-grow></base-image>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getDefaultCultureCode, getCultureID } from "@/currentCulture";
export default {
  data() {
    return {
      imagePath: "Bonus/BannerImage/",
      promoList: [],
    };
  },
  methods: {
     imgUrl(promoItem) {
      const cultureCode = getDefaultCultureCode();
      const currentLocaleID = getCultureID(cultureCode);
      const thisDescriptionItem = promoItem.description.filter((x) => x.languageId == currentLocaleID);
      return this.imagePath + thisDescriptionItem[0].image;
    },
    openPromoPage() {
      this.$router.push("/promotion");
    },
  },
  components: {},
  computed: {},
  watch: {},
  async created() {
    await this.$store.dispatch("bonus/fetchBonusList").then(() => {
      let promoList = this.$store.getters["bonus/getBonusList"].filter((bonus) => bonus.type == "Pre Bonus");
      this.promoList = promoList.slice(0, 4);
      // this.promoList = promoList.map((promoItem) => {
      //   promoItem.imgUrl = this.imagePath + promoItem.imgUrl;
      //   return promoItem;
      // });
    });
  },
};
</script>
<style scoped>
.promoImg {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 221px;
  border-radius: 20px;
}
.promoItem {
  margin: 1rem 4rem;
  padding: 8px;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .promoItem {
    margin: 1rem 1rem;
  }
}
@media (max-width: 767px) {
  .promoItem {
    margin: 1rem 0.3rem;
    padding: 0px;
  }
}
</style>
