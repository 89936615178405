<template>
	<b-container fluid class="bg-black py-2">
		<b-container align-h="center">
			<b-row>
				<b-col lg="2" class="d-none d-lg-block"></b-col>
				<b-col lg="8">
					<b-row align-h="center" align-v="center">
						<b-col cols="3">
							<BaseImage :imgSrc="logoUrl" mode="regularImg" :isFullUrl="false" fluid-grow></BaseImage>
						</b-col>
						<b-col cols="5">
							<div id="jackpotContent" class="w-100 jackpotContent">2,126,888.30</div>
						</b-col>
						<b-col cols="3" v-if="!isLogin">
							<b-button variant="join" v-b-modal="'signup-modal'">{{ $t("HomeJackpot.JOINNOW") }}</b-button>
						</b-col>
						<b-col cols="3" v-if="isLogin">
							<template v-if="isMaintenance">
								<b-button variant="join" @click="maintenancepopup" :class="{ disabled: !checkIsLoadMemberBank }">{{
									$t("HomeJackpot.DEPOSIT")
								}}</b-button>
							</template>
							<template v-else-if="this.checkIsMemberBankExist === true">
								<b-button variant="join" v-b-modal="'deposit-modal'" :class="{ disabled: !checkIsLoadMemberBank }">{{
									$t("HomeJackpot.DEPOSIT")
								}}</b-button>
							</template>
							<template v-else>
								<b-button variant="join" v-b-modal="'bankInfo-modal'" :class="{ disabled: !checkIsLoadMemberBank }">{{
									$t("HomeJackpot.DEPOSIT")
								}}</b-button>
							</template>
						</b-col>
					</b-row>
				</b-col>
				<b-col lg="2" class="d-none d-lg-block"></b-col>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import gsap from "gsap";
var jackpotAmount = 2126888.3;
const maxJackpotAmount = 2888888;
var rollingJackpot;

export default {
	data() {
		return {
			logoUrl: "918kiss/assets/images/Home/Progressive_jackpot.png",
		};
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		checkIsMemberBankExist() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
		checkIsLoadMemberBank() {
			return this.$store.getters["member/getOnLoadMemberBankStatus"];
		},
		isMaintenance() {
			return this.$store.getters["vendors/getVendor"].maintenance;		
		}
	},
	components: {
		BaseImage,
	},
	methods: {
		updateJackpot: function () {
			var counter = { var: jackpotAmount };
			var jackpotEl = document.getElementById("jackpotContent");
			var randomJackpot = Math.random() * 19 + 1;
			var Duration = 16 / randomJackpot;
			jackpotAmount = jackpotAmount + randomJackpot;
			if (maxJackpotAmount < jackpotAmount) {
				jackpotAmount = maxJackpotAmount;
				clearInterval(rollingJackpot);
			}

			gsap.to(counter, {
				duration: Duration,
				var: jackpotAmount,
				onUpdate: function () {
					var nwc = counter.var.numberFormat(2);
					jackpotEl.innerHTML = nwc;
				},
			});
		},
		jackpotCounter: function () {
			this.updateJackpot();
			rollingJackpot = setInterval(this.updateJackpot, 5000);
		},
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HeaderLogin.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		}
	},
	mounted() {
		this.jackpotCounter();
	},
	unmounted() {
		clearInterval(rollingJackpot);
	},
};
</script>

<style scoped>
.btn-join {
	background: linear-gradient(180deg, #f6de98 0%, #c99e53 100%);
	border-radius: 5px;
	color: #000000 !important;
	border-radius: 5px !important;
	width: 100%;
	height: 100%;
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 700;
	font-size: calc(10px + 0.85vw) !important;
	line-height: 31px;
	padding: 0.8rem 0;
	margin: 0.1rem auto;
}
.jackpotContent {
	border: 3px solid #bca67d;
	height: 100%;
	color: #ffffff;
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 700;
	font-size: calc(10px + 1.05vw);
	line-height: 36px;
	text-align: center;
	padding: 0.5rem 0;
	margin: 0.1rem auto;
	overflow: hidden;
}
</style>
