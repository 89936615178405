<template>
	<HomeBanner></HomeBanner>
	<AnnouncementView></AnnouncementView>
	<HomeSwitchSite></HomeSwitchSite>
	<HomeFastPayment></HomeFastPayment>
	<HomeJackpot></HomeJackpot>
	<HomeWithdrawalChart></HomeWithdrawalChart>
	<HomePromotion></HomePromotion>
	<HomeDownload></HomeDownload>
	<event-view></event-view>
</template>
<style>
.homeTitle {
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 36px;
	color: #ffd9df;
}
</style>
<script>
import AnnouncementView from "@/components/content/Announcement/AnnouncementView.vue";
import HomeFastPayment from "@/components/content/Home/HomeFastPayment.vue";
import HomeJackpot from "@/components/content/Home/HomeJackpot.vue";
import HomeBanner from "@/components/content/Home/HomeBanner.vue";
import HomeWithdrawalChart from "@/components/content/Home/HomeWithdrawalChart.vue";
import HomePromotion from "@/components/content/Home/HomePromotion.vue";
import HomeDownload from "@/components/content/Home/HomeDownload.vue";
import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";
import EventView from "@/components/content/Event/EventView.vue";

export default {
	props: ["token", "time"],
	data() {
		return {};
	},
	components: {
		HomeBanner,
		AnnouncementView,
		HomeFastPayment,
		HomeJackpot,
		HomeWithdrawalChart,
		HomePromotion,
		HomeDownload,
		HomeSwitchSite,
		EventView,
	},
	methods: {},
};
</script>
