<template>
	<b-container fluid class="fastPaymentBg">
		<b-container>
			<b-col cols="12" class="fastPaymentTitle">{{ $t("HomeFastPayment.Description1") }}</b-col>
			<b-col cols="12" class="d-flex justify-content-center fastPaymentDesc"
				>{{ $t("HomeFastPayment.Description2") }}.</b-col
			>

			<b-row class="mt-5">
				<b-col cols="4" lg="4" sm="4">
					<b-col>
						<svg
							width="80"
							height="80"
							viewBox="0 0 80 80"
							fill="white"
							xmlns="http://www.w3.org/2000/svg"
							class="fastPay-icon"
						>
							<path
								d="M58.3334 80.0001C46.3867 80.0001 36.6667 70.2801 36.6667 58.3334C36.6667 46.3868 46.3867 36.6667 58.3334 36.6667C70.2801 36.6667 80.0001 46.3868 80.0001 58.3334C80.0001 70.2801 70.2801 80.0001 58.3334 80.0001ZM58.3334 40.0001C48.2234 40.0001 40.0001 48.2268 40.0001 58.3334C40.0001 68.4401 48.2234 76.6668 58.3334 76.6668C68.4434 76.6668 76.6668 68.4401 76.6668 58.3334C76.6668 48.2268 68.4434 40.0001 58.3334 40.0001Z"
							/>
							<path
								d="M54.9996 66.6668C54.5596 66.6668 54.133 66.4901 53.8196 66.1801L47.153 59.5135C46.503 58.8635 46.503 57.8068 47.153 57.1568C47.803 56.5068 48.8596 56.5068 49.5096 57.1568L54.9163 62.5635L65.4096 50.5701C66.0163 49.8735 67.0696 49.8068 67.763 50.4135C68.4563 51.0201 68.5263 52.0701 67.9196 52.7635L56.253 66.0968C55.9496 66.4435 55.513 66.6501 55.053 66.6668C55.0363 66.6668 55.0163 66.6668 54.9996 66.6668Z"
							/>
							<path
								d="M31.6667 70.0001H8.33334C3.74 70.0001 0 66.2634 0 61.6667V18.3333C0 13.7367 3.74 10 8.33334 10H15C15.92 10 16.6667 10.7467 16.6667 11.6667C16.6667 12.5867 15.92 13.3333 15 13.3333H8.33334C5.57667 13.3333 3.33334 15.5767 3.33334 18.3333V61.6667C3.33334 64.4234 5.57667 66.6667 8.33334 66.6667H31.6667C32.5867 66.6667 33.3334 67.4134 33.3334 68.3334C33.3334 69.2534 32.5867 70.0001 31.6667 70.0001Z"
							/>
							<path
								d="M55.0001 30C54.0801 30 53.3334 29.2534 53.3334 28.3334V18.3333C53.3334 15.5767 51.0901 13.3333 48.3334 13.3333H41.6667C40.7467 13.3333 40.0001 12.5867 40.0001 11.6667C40.0001 10.7467 40.7467 10 41.6667 10H48.3334C52.9268 10 56.6668 13.7367 56.6668 18.3333V28.3334C56.6668 29.2534 55.9201 30 55.0001 30Z"
							/>
							<path
								d="M38.3334 20H18.3334C15.5767 20 13.3334 17.7567 13.3334 15V8.33334C13.3334 7.41334 14.08 6.66667 15 6.66667H20.1667C20.9434 2.86667 24.3067 0 28.3334 0C32.36 0 35.7234 2.86667 36.5 6.66667H41.6667C42.5867 6.66667 43.3334 7.41334 43.3334 8.33334V15C43.3334 17.7567 41.0901 20 38.3334 20ZM16.6667 10V15C16.6667 15.9167 17.4134 16.6667 18.3334 16.6667H38.3334C39.2534 16.6667 40.0001 15.9167 40.0001 15V10H35C34.08 10 33.3334 9.25334 33.3334 8.33334C33.3334 5.57667 31.09 3.33334 28.3334 3.33334C25.5767 3.33334 23.3334 5.57667 23.3334 8.33334C23.3334 9.25334 22.5867 10 21.6667 10H16.6667Z"
							/>
							<path
								d="M45.0001 30H11.6667C10.7467 30 10 29.2534 10 28.3334C10 27.4134 10.7467 26.6667 11.6667 26.6667H45.0001C45.9201 26.6667 46.6667 27.4134 46.6667 28.3334C46.6667 29.2534 45.9201 30 45.0001 30Z"
							/>
							<path
								d="M35 40.0001H11.6667C10.7467 40.0001 10 39.2534 10 38.3334C10 37.4134 10.7467 36.6667 11.6667 36.6667H35C35.92 36.6667 36.6667 37.4134 36.6667 38.3334C36.6667 39.2534 35.92 40.0001 35 40.0001Z"
							/>
							<path
								d="M28.3334 50.0001H11.6667C10.7467 50.0001 10 49.2534 10 48.3334C10 47.4134 10.7467 46.6667 11.6667 46.6667H28.3334C29.2534 46.6667 30 47.4134 30 48.3334C30 49.2534 29.2534 50.0001 28.3334 50.0001Z"
							/>
						</svg>
					</b-col>
					<b-col cols="12" class="fastPaymentNumber my-3">{{ fastPaymentRegPlayer }}</b-col>
					<b-col cols="12" class="fastPaymentText my-3">{{ $t("HomeFastPayment.REGISTEREDPLAYERS") }}</b-col>
				</b-col>

				<b-col cols="4" lg="4" sm="4">
					<b-col>
						<svg
							width="106"
							height="80"
							viewBox="0 0 106 80"
							fill="white"
							xmlns="http://www.w3.org/2000/svg"
							class="fastPay-icon"
						>
							<path
								d="M31.0295 4.58731C21.1029 2.43267 13.8835 7.50651 13.8835 16.4726C13.8835 22.311 19.3674 29.887 27.7669 28.5664C28.3223 28.4969 28.8776 28.4274 29.5024 28.3579C30.4742 28.2189 31.446 28.4274 31.7237 29.47C32.0708 30.7211 31.099 31.3466 29.9883 31.6247C26.6562 32.3892 23.2548 32.2502 20.2699 30.8601C18.6038 30.0956 17.4932 30.3041 16.1742 30.9991C8.12183 35.3084 3.67912 41.8419 3.47087 51.086C3.40145 53.3796 3.54028 55.8123 3.40145 58.245C3.33203 60.0521 4.0262 60.6081 5.76164 60.5386C9.44075 60.3996 13.1199 60.3996 16.799 60.5386C19.0203 60.6776 19.6451 59.7741 20.0616 57.6889C21.3805 50.3909 24.6431 44.1355 30.4048 39.2702C30.5436 40.3823 30.8907 41.3553 31.7931 42.0504C26.5174 47.7498 23.1854 54.2137 22.8383 62.0677C22.6995 65.821 22.9077 69.6437 22.6995 73.397C22.5606 75.7602 23.5325 76.2467 25.6844 76.1772C30.9601 76.1077 36.1664 76.3162 41.4421 76.3857C41.4421 77.4283 41.4421 78.4708 41.3727 79.5829C35.0557 79.6524 28.7388 79.7219 22.4218 79.7219C20.0616 79.7219 19.1592 78.6099 19.2286 76.3162C19.3674 73.119 19.0898 69.9218 19.298 66.655C19.4368 64.4309 18.7427 63.7358 16.4519 63.7358C10.9679 63.7358 5.48397 64.2919 0 63.3188C0 57.5499 0 51.8505 0 46.0816C2.70728 38.0191 7.01115 31.1381 15.133 27.4544C8.33008 18.9053 9.16309 9.45264 16.799 3.33623C19.0203 1.59861 21.7276 1.32059 24.0878 0C25.4762 0 26.8645 0 28.2528 0C28.947 0.834057 29.9189 1.32059 31.0295 1.4596C31.0295 2.57168 31.0295 3.54474 31.0295 4.58731Z"
							/>
							<path
								d="M76.568 40.6604C81.5661 45.4563 84.4816 51.2947 85.5229 58.0366C85.8699 60.1218 86.4947 60.6778 88.5078 60.6083C92.2563 60.4693 96.0743 60.5388 99.8922 60.6083C101.142 60.6083 102.252 60.6083 102.183 58.8707C101.975 54.4224 102.738 49.9741 101.489 45.5258C100.031 40.3824 97.185 36.2121 92.8811 33.0149C90.035 30.8603 87.1889 29.3312 83.5098 31.3468C81.5661 32.3894 79.2059 32.0419 77.0539 31.8333C75.5962 31.6943 73.5831 31.6248 73.8608 29.6092C74.1384 27.6631 76.0127 28.3581 77.2622 28.4971C83.8568 29.1227 89.0631 25.9949 91.0068 20.1566C93.7141 12.0245 88.369 4.93502 78.5811 3.75343C78.5811 2.57185 78.5811 1.39027 78.5811 0.278198C79.4836 0.278198 80.386 0.278198 81.3578 0.278198C83.1627 1.52928 85.384 1.52928 87.1889 2.78037C96.2131 9.1053 97.4626 18.0019 90.4515 27.7326C98.1568 31.7638 103.363 37.8803 105.029 46.5684C106.001 51.6422 105.376 56.8551 105.446 61.9984C105.446 64.153 103.71 64.0835 102.183 64.0835C97.8097 64.0835 93.4364 64.2226 89.1326 64.0835C86.9806 64.014 86.0782 64.5701 86.217 66.9332C86.4253 70.1305 86.1476 73.3277 86.2864 76.5249C86.4253 79.0966 85.1758 80.0001 82.8156 80.0001C80.3166 79.9306 77.7481 79.9306 75.2491 79.8611C75.2491 78.8185 75.1797 77.7065 75.1797 76.6639C77.1234 76.5944 79.067 76.5249 81.0107 76.5249C82.3297 76.5249 82.8156 76.0384 82.8156 74.7178C83.1627 63.6665 83.2321 52.7543 75.1797 43.7882C75.6656 42.6066 76.1515 41.6335 76.568 40.6604Z"
							/>
							<path
								d="M31.7237 42.1197C30.8213 41.4247 30.4742 40.4516 30.3354 39.3395C32.2096 37.1154 34.7087 35.7253 37.2077 34.4047C38.3878 33.7792 39.0125 33.3621 37.8324 31.972C29.0164 21.5463 33.9451 3.75312 49.2863 0.833917C49.7722 0.764412 50.1887 0.694907 50.3276 0.208374C51.9241 0.208374 53.5207 0.208374 55.1173 0.208374C56.0892 1.80698 57.894 1.18144 59.213 1.73748C58.9353 2.78005 58.6576 3.75312 58.38 4.79569C51.8547 2.50203 43.8717 4.44816 39.7067 10.5646C35.9582 16.1945 35.8888 23.701 39.915 29.1223C43.8023 34.3352 50.9523 36.9764 56.5751 35.3083C56.5751 36.4203 56.5751 37.5324 56.5751 38.575C53.0348 39.3395 49.2863 39.6176 46.2319 37.7409C41.9281 35.0998 38.8043 36.8374 35.5417 39.27C34.2922 40.2431 33.0426 41.1467 31.7237 42.1197Z"
							/>
							<path
								d="M78.6494 0.139099C78.6494 1.32068 78.6494 2.50226 78.6494 3.61434C76.7057 3.89235 74.9009 4.44839 73.1654 5.35195C72.1936 5.83849 71.0829 5.9775 70.5276 4.79592C69.9028 3.54483 70.8053 2.84978 71.7771 2.43276C73.5819 1.5987 75.4562 0.903651 77.2611 0.139099C77.6776 0.139099 78.1635 0.139099 78.6494 0.139099Z"
							/>
							<path
								d="M41.3734 79.6522C41.3734 78.6096 41.3734 77.567 41.4428 76.455C48.3151 76.455 55.1874 76.455 62.1292 76.455C62.1292 77.4975 62.1292 78.6096 62.1292 79.6522C55.1874 79.9302 48.2457 79.9302 41.3734 79.6522Z"
							/>
							<path
								d="M62.0584 79.6522C62.0584 78.6096 62.0584 77.4975 62.0584 76.455C66.4316 76.455 70.8049 76.455 75.1782 76.455C75.1782 77.4975 75.2476 78.6096 75.2476 79.6522C70.8744 79.9302 66.4316 79.9302 62.0584 79.6522Z"
							/>
							<path
								d="M56.5754 38.5752C56.5754 37.4631 56.5754 36.351 56.5754 35.3084C61.7123 33.7793 65.5302 30.7211 67.5433 25.7168C70.8754 17.4457 67.4739 9.59169 58.4497 4.72636C58.7273 3.68379 59.005 2.71072 59.2827 1.66815C71.7778 6.53348 75.9428 20.5734 67.9598 31.4857C66.8492 32.9453 66.6409 33.5013 68.5152 34.4049C71.5695 35.934 74.4157 37.8801 76.637 40.5908C76.1511 41.5639 75.6652 42.5369 75.1792 43.51C74.0686 42.5369 73.0273 41.4944 71.8472 40.5908C67.9598 37.6021 64.2113 34.1964 58.8662 37.9496C58.2414 38.4362 57.339 38.4362 56.5754 38.5752Z"
							/>
							<path
								d="M31.0292 4.58723C31.0292 3.61416 31.0986 2.57159 31.0986 1.59852C31.5151 1.59852 32.0704 1.52902 32.4175 1.73753C33.667 2.43258 36.0272 2.7801 35.0554 4.79574C33.9447 6.81138 32.3481 5.28227 31.0292 4.58723Z"
							/>
						</svg>
					</b-col>
					<b-col cols="12" class="fastPaymentNumber my-3">{{ fastPaymentActivePlayer }}</b-col>
					<b-col cols="12" class="fastPaymentText my-3">{{ $t("HomeFastPayment.ACTIVEPLAYERS") }}</b-col>
				</b-col>

				<b-col cols="4" lg="4" sm="4">
					<b-col>
						<svg
							width="71"
							height="80"
							viewBox="0 0 71 80"
							fill="white"
							xmlns="http://www.w3.org/2000/svg"
							class="fastPay-icon"
						>
							<path
								d="M36.1695 43.6605C36.0215 43.6285 35.8697 43.6227 35.7197 43.6452C34.2172 43.4702 33.0837 42.199 33.0812 40.6869C33.0812 39.9622 32.4936 39.3746 31.7689 39.3746C31.0443 39.3746 30.4567 39.9622 30.4567 40.6869C30.4606 43.212 32.1495 45.4238 34.5843 46.0921V47.4044C34.5843 48.129 35.1719 48.7166 35.8966 48.7166C36.6213 48.7166 37.2088 48.129 37.2088 47.4044V46.1716C40.0191 45.5834 41.9291 42.966 41.6337 40.1102C41.3377 37.2544 38.9318 35.0842 36.0606 35.0836C34.4152 35.0836 33.0812 33.7496 33.0812 32.1042C33.0812 30.4588 34.4152 29.1247 36.0606 29.1247C37.706 29.1247 39.04 30.4588 39.04 32.1042C39.04 32.8288 39.6276 33.4164 40.3523 33.4164C41.0769 33.4164 41.6645 32.8288 41.6645 32.1042C41.6607 29.4528 39.8032 27.166 37.2088 26.6188V25.4245C37.2088 24.6998 36.6213 24.1122 35.8966 24.1122C35.1719 24.1122 34.5843 24.6998 34.5843 25.4245V26.6989C31.8766 27.4383 30.1338 30.0673 30.5067 32.8494C30.8796 35.6314 33.2535 37.7081 36.0606 37.7074C37.6842 37.7074 39.0086 39.0075 39.0381 40.6305C39.0682 42.2535 37.7919 43.6016 36.1695 43.6605Z"
							/>
							<path
								d="M17.0321 36.4964C17.0321 47.0058 25.5513 55.5251 36.0607 55.5251C46.5701 55.5251 55.0894 47.0058 55.0894 36.4964C55.0894 25.987 46.5701 17.4678 36.0607 17.4678C25.5565 17.4793 17.0442 25.9922 17.0321 36.4964ZM36.0607 20.0922C45.1208 20.0922 52.4649 27.4364 52.4649 36.4964C52.4649 45.5565 45.1208 52.9006 36.0607 52.9006C27.0007 52.9006 19.6565 45.5565 19.6565 36.4964C19.6668 27.4409 27.0052 20.1025 36.0607 20.0922Z"
							/>
							<path
								d="M61.5582 58.7601L51.2788 63.6169C49.8596 60.9771 47.1371 59.2971 44.141 59.2125L34.6516 58.9517C33.1491 58.9088 31.6748 58.5282 30.3388 57.8381L29.3732 57.3377C24.4293 54.7607 18.5364 54.7671 13.5976 57.3544L13.6578 55.1669C13.6777 54.4422 13.1068 53.8386 12.3821 53.8188L1.97654 53.5324C1.25187 53.5125 0.64893 54.0834 0.629067 54.8081L0.000502971 77.6427C-0.0193598 78.3674 0.551538 78.971 1.27621 78.9908L11.6818 79.2772H11.7177C12.4289 79.2772 13.0107 78.7115 13.0299 78.0009L13.06 76.9084L15.7633 75.461C16.8244 74.8901 18.0648 74.7543 19.2239 75.0823L35.3667 79.613C35.3949 79.6213 35.4231 79.6277 35.4519 79.6341C36.6187 79.8782 37.8079 80.0006 38.9997 80C41.5242 80.0013 44.0192 79.4573 46.3137 78.4039C46.3701 78.3783 46.4246 78.3482 46.4765 78.3142L69.882 63.1748C70.4651 62.7974 70.6534 62.0318 70.3119 61.4269C68.5742 58.3437 64.7202 57.1692 61.5582 58.7601ZM2.66021 76.4035L3.21637 56.1921L10.9981 56.4061L10.442 76.6182L2.66021 76.4035ZM45.1315 76.0601C42.2809 77.3467 39.0971 77.7017 36.0331 77.0757L19.9339 72.5565C18.1219 72.0446 16.1824 72.2567 14.5248 73.1479L13.1433 73.888L13.513 60.4498C17.8906 57.5383 23.5047 57.239 28.1667 59.6681L29.1323 60.1685C30.82 61.0399 32.6814 61.5204 34.5799 61.5756L44.0698 61.8363C46.7353 61.9132 48.999 63.8111 49.5398 66.4227L35.4045 66.0325C34.6798 66.0127 34.0769 66.5842 34.0564 67.3082C34.0365 68.0329 34.6081 68.6365 35.3321 68.6564L50.9315 69.0856H50.968C51.6786 69.085 52.2598 68.5192 52.279 67.8093C52.295 67.239 52.2527 66.6681 52.1521 66.1069L62.6955 61.1251C62.7057 61.1206 62.716 61.1155 62.7262 61.1104C64.2217 60.3537 66.0299 60.6068 67.2601 61.7453L45.1315 76.0601Z"
							/>
							<path
								d="M37.3728 13.7784V1.31223C37.3728 0.587557 36.7852 0 36.0606 0C35.3359 0 34.7483 0.587557 34.7483 1.31223V13.7784C34.7483 14.5031 35.3359 15.0907 36.0606 15.0907C36.7852 15.0907 37.3728 14.5031 37.3728 13.7784Z"
							/>
							<path
								d="M47.2146 13.7784V7.87339C47.2146 7.14871 46.6271 6.56116 45.9024 6.56116C45.1777 6.56116 44.5901 7.14871 44.5901 7.87339V13.7784C44.5901 14.5031 45.1777 15.0907 45.9024 15.0907C46.6271 15.0907 47.2146 14.5031 47.2146 13.7784Z"
							/>
							<path
								d="M27.5311 13.7784V7.87339C27.5311 7.14871 26.9436 6.56116 26.2189 6.56116C25.4942 6.56116 24.9066 7.14871 24.9066 7.87339V13.7784C24.9066 14.5031 25.4942 15.0907 26.2189 15.0907C26.9436 15.0907 27.5311 14.5031 27.5311 13.7784Z"
							/>
						</svg>
					</b-col>
					<b-col cols="12" class="fastPaymentNumber my-3">{{ fastPaymentMinutes }} min(s)</b-col>
					<b-col cols="12" class="fastPaymentText my-3">{{ $t("HomeFastPayment.Withdrawalsspeed") }}</b-col>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>
<script>
export default {
	data() {
		return {
			fastPaymentRegPlayer: 1187,
			fastPaymentActivePlayer: 0,
			fastPaymentMinutes: 2,
			maxActivePlayerPercentage: 0.9,
			minActivePlayerPercetange: 0.6,
		};
	},
	async created() {
		const defaultRegPlayer = this.fastPaymentRegPlayer;
		await this.$store
			.dispatch("member/getMemberCount")
			.then(() => {
				if (this.$store.getters["member/getMemberCount"]) {
					const RegPlayer = this.$store.getters["member/getMemberCount"];
					this.fastPaymentRegPlayer = RegPlayer + 1000;
				}
			})
			.catch(() => {
				this.fastPaymentRegPlayer = defaultRegPlayer;
			});
		const activePlayerMin = Math.floor(this.fastPaymentRegPlayer * this.minActivePlayerPercetange);
		const activePlayerMax = Math.floor(this.fastPaymentRegPlayer * this.maxActivePlayerPercentage) - activePlayerMin;
		this.fastPaymentActivePlayer = Math.floor(Math.random() * activePlayerMax + activePlayerMin);
	},
};
</script>
<style scoped>
.fastPaymentBg {
	background: #6d2120;
	padding: 3rem 0rem;
}
.fastPaymentDesc {
	font-size: 1rem;
}
.fastPaymentTitle {
	color: #ff9a99;
	font-family: SF Pro Display;
	font-size: 36px;
	font-weight: 700;
	display: flex;
	justify-content: center;
}
.fastPaymentNumber {
	font-family: SF Pro Display;
	color: #e64c4f;
	font-size: 1.8rem;
	font-weight: 700;
	letter-spacing: 0em;
	text-align: center;
}
.fastPaymentText {
	font-family: SF Pro Display;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0em;
	display: flex;
	justify-content: center;
}

@media (max-width: 767px) {
	.fastPaymentTitle {
		font-size: 1.4rem;
		padding-bottom: 1rem;
	}
	.fastPaymentDesc {
		font-size: 0.85rem;
	}
	.fastPaymentNumber {
		font-size: 1.4rem;
	}
	.fastPaymentBg {
		padding: 1rem 0rem;
	}
	.fastPay-icon {
		width: 18vw;
	}
}
</style>
